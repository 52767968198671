<template>
  <iq-card class="side-iq-card">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        Careers
      </h4>
    </template>
    <template v-slot:headerAction>
      <!-- <p class="m-0" v-if="userRoleAllowedToPostOrNot()"><a @click="goToCareerCreate()" style="cursor: pointer;"> Create </a></p> -->
    </template>
    <template v-slot:body>
      <hr class="m-0"/>
      <ul class="media-story m-0 p-0 ListPage">
        <li v-for="(carr,index) in careerListObj" :key="index" class="d-flex align-items-center mt-3 btn p-0 ImgList" @click="goToCareerView(carr.carr_id)">
          <!-- <img src="https://img.etimg.com/thumb/msid-69923083,width-1070,height-580,overlay-economictimes/photo.jpg" class="avatar-50 rounded-circle" style="object-fit: cover;" alt="Careers"> -->
          <div class="rounded-circle"  style="border: 2px solid rgba(0, 0, 0, 0.1);padding: 2px;">
              <b-avatar style="border-radius: 100%!important;" class="avatar-50 rounded" v-if="carr.carr_profile_image">
              <img :src="carr.carr_profile_image" class="avatar-50 rounded" alt="career_image">
            </b-avatar>
            <b-avatar variant="primary" class="b-avatar rounded-circle avatar-50" :text="getFirstLetterOfAString(carr.carr_field)" v-else >
            </b-avatar>
          </div>
          <div class="stories-data ml-2 d-flex flex-fill justify-content-between leftAlign" >
            <h5 class="text-left homeHeading">{{ carr.carr_field }}
              <br>
              <span style="position: relative;top: -5px;font-size:14px; color: #8c8181">
                {{ carr.carr_cat_name }}
              </span>
            </h5>
            <button style="all:unset; font-weight: 500; cursor: pointer;" v-if="careerFollowObj && careerFollowObj[carr.carr_id]" @click="followCareer($event, carr.carr_id, careerFollowObj[carr.carr_id].followed_by_curr_user)">
              <span v-if="careerFollowObj[carr.carr_id].followed_by_curr_user=== 0" class="text-primary">Follow</span>
              <span v-else class="text-secondary">Unfollow</span>
            </button>
          </div>
        </li>
      </ul>
      <b-btn class="d-block mt-3 border-light iq-bg-dark" style="width: 100%;" @click="goToCareerList()">
        <i class="ri-add-line"></i> See All
      </b-btn>
    </template>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </iq-card>
</template>
<style lang="scss" scoped>
  .ImgList {
    img {
      height: 48px;
      width: 48px;
      object-fit: cover;
    }
  }
</style>
<script>
import { Careers } from "../../../../FackApi/api/career.js"
import { Follows } from "../../../../FackApi/api/follow"
import ApiResponse from "../../../../Utils/apiResponse.js"

export default {
  name: "CareerHomeList",
  mounted () {
    this.loadRequiredData()
  },
  components: {
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  data () {
    return {
      careerListObj: [],
      careerImgObj: {},
      careerFollowObj: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Follow Response",
      vmFollowData: Object.assign({}, this.initFollowData())
    }
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.careerList()
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * goToCareerList
     */
    goToCareerList () {
      this.$router.push("/careersList")
    },
    /**
     * goToCareerView
     */
    goToCareerView (carrId) {
      this.$router.push("/career/" + carrId)
    },
    /**
     * userRoleAllowedToPostOrNot
     */
    userRoleAllowedToPostOrNot () {
      if (this.userData && this.userData.user_role) {
        return (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118")
      }
    },
    /*
   * careerList
   * */
    async careerList () {
      try {
        let filter = {
          limit: 3
        }
        let careerListResp = await Careers.careerList(this, "", filter)
        if (careerListResp && careerListResp.resp_status) {
          this.careerListObj = careerListResp.resp_data.data
          this.careerImgObj = careerListResp.carr_images
          this.careerFollowObj = careerListResp.carr_follow
        }
      }
      catch (err) {
        console.error("Exception occurred at careerList() and Exception:", err.message)
      }
    },
    /**
     *  followCareer ()
     */
    async followCareer (event, moduleId, followStatus) {
      event.stopPropagation()
      try {
        const a = 1
        if (a === 1) {
          this.showToast = true
          this.toastMsg = "Comming Soon"
          return
        }
        this.vmFollowData.module_id = moduleId
        this.vmFollowData.module_name = "CARR"
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          return
        }
        else if (this.careerFollowObj[moduleId]) {
          this.careerFollowObj[moduleId].followed_by_curr_user = this.vmFollowData.follow_status
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followCareer() and Exception", err.message)
      }
    }
  }
}
</script>
